import { Button, Col, Container, Row } from "react-bootstrap";
import BarChart from "../Charts/BarChart";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useCallback, useEffect, useContext } from "react";
import { useAuth } from "../Context/AuthContext";
const styles = {
  MainDiv: {
    backgroundColor: "#f4f4f4",
    width: "100vw",
    height: "100vh",
  },

  card: {
    height: "260px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  backButton: {
    background: "transparent",
    border: "none",
    fontWeight: "bold",
    color: "#000078",
    cursor: "pointer",
    alignItems: "center",
    boxShadow: "none",
  },
};

const DetailedCharts = () => {
  const { token } = useAuth();
  const [detailedChartData, setdetailedChartData] = useState([]);
  const URL = process.env.REACT_APP_URL;
  const fetchDetailedChartsData = useCallback(async () => {
    let url = `${URL}get-detailed-chart-data`;
    try {
      const response = await axios.get(url,{
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      setdetailedChartData(response?.data?.data);
      //toast.success("Other Consumption Data Updated");
    } catch (err) {
      console.error(err.response.data.message);
    }
  }, []);

  useEffect(() => {
    fetchDetailedChartsData();
    console.log("detailedChartData", detailedChartData);
  }, []);

  useEffect(() => {
    fetchDetailedChartsData();
  }, [fetchDetailedChartsData]);

  const DetailsChart = [
    [
      {
        data: 0.8,
        day: "Mon",
      },
      {
        data: 0.6,
        day: "Tue",
      },
      {
        data: 0.8,
        day: "Wed",
      },
      {
        data: 0.3,
        day: "Thu",
      },
    ],
    [
      {
        data: 0.3,
        day: "Mon",
      },
      {
        data: 0.4,
        day: "Tue",
      },
      {
        data: 0.8,
        day: "Wed",
      },
      {
        data: 0.6,
        day: "Thu",
      },
      {
        data: 0.2,
        day: "Fri",
      },
      {
        data: 0.7,
        day: "Sat",
      },
    ],
    [
      {
        data: 0.8,
        day: "Mon",
      },
      {
        data: 0.6,
        day: "Tue",
      },
      {
        data: 0.5,
        day: "Wed",
      },
      {
        data: 0.8,
        day: "Thu",
      },
      {
        data: 0.6,
        day: "Fri",
      },
      {
        data: 0.3,
        day: "Sat",
      },
    ],
    [
      {
        data: 0.3,
        day: "Mon",
      },
      {
        data: 0.5,
        day: "Tue",
      },
      {
        data: 0.8,
        day: "Wed",
      },
      {
        data: 0.4,
        day: "Thu",
      },
      {
        data: 0.6,
        day: "Fri",
      },
      {
        data: 0.8,
        day: "Sat",
      },
    ],
    [
      {
        data: 0.6,
        day: "Mon",
      },
      {
        data: 0.8,
        day: "Tue",
      },
      {
        data: 0.5,
        day: "Wed",
      },
      {
        data: 0.8,
        day: "Thu",
      },
      {
        data: 0.5,
        day: "Fri",
      },
      {
        data: 0.7,
        day: "Sat",
      },
    ],
    [
      {
        data: 0.7,
        day: "Mon",
      },
      {
        data: 0.4,
        day: "Tue",
      },
      {
        data: 0.5,
        day: "Wed",
      },
      {
        data: 0.8,
        day: "Thu",
      },
      {
        data: 0.5,
        day: "Fri",
      },
      {
        data: 0.6,
        day: "Sat",
      },
    ],
  ];

  return (
    <div style={styles.MainDiv}>
      <Container fluid style={{ padding: "40px" }}>
        <Row>
          <Col
            sm={12}
            xs={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Link to="/cxodashboard">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Button style={styles.backButton}>
                  <img
                    className="img"
                    alt="Vector"
                    src={require("../Assets/arrowLeft.png")}
                    height={20}
                    width={20}
                  />
                </Button>

                <div
                  style={{
                    color: "#375EAD",
                    fontWeight: "600",
                    marginLeft: "20px",
                  }}
                >
                  Back to dashboard
                </div>
              </div>
            </Link>
          </Col>
          {detailedChartData.length > 0 &&
            detailedChartData.map((data, index) => (
              <Col
                key={index}
                sm={12}
                xs={12}
                md={12}
                lg={6}
                xl={4}
                xxl={4}
                style={{ padding: "20px" }}
              >
                <div className="card" style={styles.card}>
                  <BarChart
                    data={{
                      labels: data.map((item) => item.month),
                      datasets: [
                        {
                          label: "Neutrality",
                          data: data.map((item) => item?.data),
                          barThickness: 15,
                          maxBarThickness: 15,
                          barPercentage: 1,
                          categoryPercentage: 1,
                        },
                      ],
                    }}
                    width={400}
                    height={240}
                    titleX={
                      index === 0
                        ? "NEUTRALITY (Last 4 months)"
                        : index === 1
                        ? "A1 : Rainwater Storage"
                        : index === 2
                        ? "A2 : Ground Recharge"
                        : index === 3
                        ? "A3 : Offsite Recharge"
                        : index === 4
                        ? "A4 : Recycled Water"
                        : index === 5
                        ? "C  : Freshwater Consumed"
                        : null
                    }
                    titleY={
                      index === 0
                        ? "NEUTRALITY (Last 4 months)"
                        : "Volume (KL * 10\u2075)"
                    }
                    color={
                      index === 0
                        ? "#71a6af"
                        : index === 1
                        ? "#617834"
                        : index === 2
                        ? "#ba7e54"
                        : index === 3
                        ? "#77b585"
                        : index === 4
                        ? "#9e65a3"
                        : index === 5
                        ? "#7a4d62"
                        : "#6992CC"
                    }
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default DetailedCharts;
