import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "../Layout/Tabs";
import PlantShopsBlocksTabs from "../Layout/PlantShopsBlocksTabs";
import axios from "axios";
import {
  ALTROZ_BIW,
  CANTEEN_J,
  CANTEEN_K,
  CAR_PLANT,
  COMPRESSOR_HOUSE,
  ETP_SHOP,
  HARRIER_SAFARI,
  JLR,
  NEXON_BIW,
  NOVA_BIW,
  PAINT_SHOP,
  POWERTRAIN,
  PRESS_SHOP,
  TCF_1,
  TCF_2,
} from "../Reports/utils/Constants";
import { useAuth } from "../Context/AuthContext";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    margin: "auto",
    backgroundColor: "rgb(249, 249, 249)",
  },
  card: {
    height: "97px",
    width: "314px",
    border: "none",
    margin: "40px",
    borderRadius: "4.361px",
    boxShadow:
      "0px 0px 7.63194465637207px 1.0902777910232544px rgba(0, 0, 0, 0.20)",
  },
  subDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
  ImgDiv: { borderRight: "solid black 3px" },
  Img: { marginRight: "20px", height: "50px", width: "50px" },
  TextDiv: { textAlign: "left", marginRight: "30px", color: "navy" },
  MainFont: { fontWeight: "bold" },
};
const cardData = [
  {
    shopId: CAR_PLANT,
    imageSrc: require("../Assets/PVBU.png"),
    title: "Car Plant",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/shopreport",
  },
  {
    shopId: PRESS_SHOP,
    imageSrc: require("../Assets/power-press.png"),
    title: "Press Shop",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/pressreports",
  },
  {
    shopId: NEXON_BIW,
    imageSrc: require("../Assets/weld.png"),
    title: "Nexon BIW",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/nexonBiw",
  },
  {
    shopId: PAINT_SHOP,
    imageSrc: require("../Assets/paint-roller (3).png"),
    title: "Paint Shop",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/paintreports",
  },
  {
    shopId: TCF_1,
    imageSrc: require("../Assets/engine.png"),
    title: "TCF - 1",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/tcf1reports",
  },
  {
    shopId: TCF_2,
    imageSrc: require("../Assets/engine.png"),
    title: "TCF - 2",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/tcf2reports",
  },
  {
    shopId: JLR,
    imageSrc: require("../Assets/PVBU.png"),
    title: "JLR",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/jlrreports",
  },
  {
    shopId: POWERTRAIN,
    imageSrc: require("../Assets/gearbox.png"),
    title: "Powertrain",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/powerTrain",
  },
  {
    shopId: ALTROZ_BIW,
    imageSrc: require("../Assets/weld.png"),
    title: "Altroz BIW",
    subtitle: "J-Block, Pune",
    linkTo: "/reports/altroxBiw",
  },
  {
    shopId: NOVA_BIW,
    imageSrc: require("../Assets/weld.png"),
    title: "Nova BIW",
    subtitle: "J-Block, Pune",
    linkTo: "/reports/novaBiw",
  },
  {
    shopId: HARRIER_SAFARI,
    imageSrc: require("../Assets/PVBU.png"),
    title: "Harrier/Safari",
    subtitle: "J-Block, Pune",
    linkTo: "/reports/harrierSafari",
  },
  {
    shopId: COMPRESSOR_HOUSE,
    imageSrc: require("../Assets/air-compressor.png"),
    title: "Compressor House",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/compressorHouse",
  },
  {
    shopId: ETP_SHOP,
    imageSrc: require("../Assets/production.png"),
    title: "ETP",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/etpreport",
  },
  {
    shopId: CANTEEN_K,
    imageSrc: require("../Assets/eco-food (1).png"),
    title: "Canteen",
    subtitle: "K-Block, Pune",
    linkTo: "/reports/canteen1",
  },
  {
    shopId: CANTEEN_J,
    imageSrc: require("../Assets/eco-food (1).png"),
    title: "Canteen J",
    subtitle: "J-Block, Pune",
    linkTo: "/reports/canteen2",
  },
];

const PlantShops = () => {
  const URL = process.env.REACT_APP_URL;
  const { token } = useAuth();
  const [accessibleShops, setAccessibleShops] = useState([]);
  const getShops = async () => {
    try {
      const response = await axios.get(`${URL}getUserAccessibleShops`, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      console.log(response?.data?.shops);
      setAccessibleShops(response?.data?.shops);
    } catch (error) {
      console.error("Report error:", error);
    }
  };

  useEffect(() => {
    getShops();
  }, [token]);

  return (
    <div style={styles.MainDiv}>
      <Tabs />
      <PlantShopsBlocksTabs />
      <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Row>
          {cardData
            .filter((item) => accessibleShops.includes(item.shopId))
            .map((card, index) => (
              <Col key={index} sm={12} xs={12} md={12} lg={6} xl={4} xxl={4}>
                <Link to={card.linkTo}>
                  <div className="card" style={styles.card}>
                    <div style={styles.subDiv}>
                      <div style={styles.ImgDiv}>
                        <img
                          src={card.imageSrc}
                          style={styles.Img}
                          className="d-inline-block align-top"
                          alt="logo"
                        />
                      </div>
                      <div style={styles.TextDiv}>
                        <div style={styles.MainFont}>{card.title}</div>
                        <div>{card.subtitle}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default PlantShops;
