import React, { createContext, useContext, useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const AuthContext = createContext();
const secretKey = 'qwertyuiop';

const decryptedToken = (encryptedData) => {
  if (encryptedData) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
    //alert(`Decrypted Data: ${decryptedData}`);
    return decryptedData;
} else {
    return null
};
}


export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const token = decryptedToken(sessionStorage.getItem("token"));
  const navigate = useNavigate();
  const [user, setUser] = useState(false);

  const logout = () => {
    sessionStorage.removeItem("token");
    navigate("/login");
  };

  const isTokenExpired = (token) => {
    if (!token) return false; // Token does not exist or is invalid
    const expirationTime = jwtDecode(token).exp * 1000; // Convert expiration time to milliseconds
    return Date.now() >= expirationTime;
  };

  const isAuthenticated = () => {
    const token = decryptedToken(sessionStorage.getItem("token"));
    if (isTokenExpired(token)) {
      logout();
    }
    return token && !isTokenExpired(token);
  };

  return (
    <AuthContext.Provider value={{ user, logout, isAuthenticated, token }}>
      {children}
    </AuthContext.Provider>
  );
};
