import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./styles/LiveTrendTable.css";
import { useAuth } from "../Context/AuthContext";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import {
  Alert,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { format } from "date-fns";
import { Loader } from "../Loader/Loader";
import Meter from "./Components/Meter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Register the Filler plugin
);

const styles = {
  flowRateDiv: {
    background: "#008000",
    width: "348px",
    height: "34px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "18px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
};

const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 15,
      },
      marginTop: 30,
    },
    title: {
      display: false,
      text: "Multi Axis",
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  layout: {
    padding: {
      bottom: 50,
      left: 10,
      top: 0,
      right: 10,
    },
  },
};

const LiveTrend = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { meter, title } = useParams();
  const URL = process.env.REACT_APP_URL;
  const { token } = useAuth();


  useEffect(() => {
    const fetchData = async () => {
      let url = `${URL}getLiveDataByMeterNumber?meter=${meter}`;
      try {
        const response = await axios.get(url,{
          headers: {
            Authorization: `abc ${token}`,
          },
        });
        if (response) {
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
        setData(response?.data?.data);
      } catch (err) {
        console.error(err.response.data.message);
        setLoading(false);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const defaultData = {
    labels: data?.map((item) =>
      format(new Date(`${item?.D} ${item?.T}`), "HH:mm")
    ),
    datasets: [
      {
        label: "Volume",
        data: data?.map((item) => Math.round(item[`M${meter}TV`])),
        borderColor: "#375EAD",
        backgroundColor: "rgba(161, 183, 230,0.2)",
        yAxisID: "y",
        tension: 0.1,
        fill: true,
      },
      {
        label: "Flow Rates",
        data: data?.map((item) => Math.round(item[`M${meter}FR`])),
        borderColor: "green",
        backgroundColor: "green",
        yAxisID: "y1",
      },
    ],
  };
  return loading ? (
    <Loader />
  ) : data?.length > 0 ? (
    <Container>
      <div
        style={{
          textAlign: "center",
          marginTop: 30,
          marginBottom: 30,
          backgroundColor: "#ECF1FA",
          color: "#3B61AF",
          borderRadius: 5,
          padding: 5,
          border: "1px solid #3B61AF",
        }}
      >
        <h1>Live Analytics</h1>
      </div>
      <Card
        style={{
          marginBottom: 40,
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        }}
      >
        <Card.Header style={{ fontWeight: "bold" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Spinner
                animation="grow"
                size="sm"
                variant="success"
                style={{ marginRight: 5 }}
              />
              {title}
            </div>
            <div>{format(new Date(), "dd/MM/yyyy")}</div>
          </div>
        </Card.Header>
        <Card.Body>
          <Line
            id="lineChart"
            options={options}
            data={defaultData}
            height={615}
            width={2000}
            style={{ marginTop: 20 }}
          />
        </Card.Body>
      </Card>

      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>Time</td>
            <td>
              Flow Rate (m<sup>3</sup>/h)
            </td>
            <td>
              Volume (m<sup>3</sup>)
            </td>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td>{format(new Date(item?.D), "dd/MM/yyyy")}</td>
              <td>{format(new Date(`${item?.D} ${item?.T}`), "HH:mm")}</td>
              <td>{Math.round(item[`M${meter}FR`])}</td>
              <td>{Math.round(item[`M${meter}TV`])}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  ) : (
    <Container style={{ marginTop: 30, marginBottom: 20 }}>
      <div
        style={{
          textAlign: "center",
          marginTop: 30,
          marginBottom: 30,
          backgroundColor: "#ECF1FA",
          color: "#3B61AF",
          borderRadius: 5,
          padding: 5,
          border: "1px solid #3B61AF",
        }}
      >
        <h1>Live Analytics</h1>
      </div>
      <Card
        style={{
          marginBottom: 40,
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        }}
      >
        <Card.Header style={{ fontWeight: "bold" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Spinner
                animation="grow"
                size="sm"
                variant="danger"
                style={{ marginRight: 5 }}
              />
              {title}
            </div>
            <div>{format(new Date(), "dd/MM/yyyy")}</div>
          </div>
        </Card.Header>
        <Card.Body>
          <Alert variant="info" style={{ textAlign: "center" }}>
            Data not available for selected meter
          </Alert>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LiveTrend;
