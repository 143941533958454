import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { BillingTable } from "./BillingTable";
import BarChart from "../Charts/BarChart";
import Tabs from "../Layout/Tabs";
import WaterNetTabs from "../Water Networks/Components/WaterNetTabs";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
const styles = {
  MainDiv: {
    width: "100vw",
    height: "1100px",
  },
  card: {
    backgroundColor: "#F9F9F9",
    height: "140px",
    border: "none",
    margin: "20px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card1: {
    backgroundColor: "#F9F9F9",
    height: "70px",
    border: "none",
    margin: "20px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "0px 50px",
  },
  dropdownCard: {
    backgroundColor: "#FFF",
    height: "70px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  card2: {
    backgroundColor: "#F9F9F9",
    height: "310px",
    border: "none",
    margin: "20px 40px",
    display: "flex",
  },
  card3: {
    backgroundColor: "#F9F9F9",
    height: "420px",
    border: "none",
    margin: "20px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Bill = () => {
  const { token } = useAuth();
  const [selectedOption, setSelectedOption] = useState("2024 - 2025");
  const { key } = useParams();
  const [consumptionBillData, setConsumptionBillData] = useState();
  const URL = process.env.REACT_APP_URL;
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const fetchMonthwisePaintShopBill = useCallback(async () => {
    let url = `${URL}getPaintShopBill?startYear=${selectedOption
      .split("-")[0]
      .trim()}&endYear=${selectedOption.split("-")[1].trim()}`;
    console.log(url);
    try {
      const response = await axios.get(url,{
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      response?.data?.data && setConsumptionBillData(response?.data?.data);
    } catch (err) {
      console.error(err.response.data.message);
    } finally {
    }
  }, [selectedOption]);

  useEffect(() => {
    fetchMonthwisePaintShopBill();
  }, [selectedOption, fetchMonthwisePaintShopBill]);

  const DetailsChart = [
    {
      data: 0.9,
      day: "Mon",
    },
    {
      data: 0.6,
      day: "Tue",
    },
    {
      data: 0.8,
      day: "Wed",
    },
    {
      data: 0.3,
      day: "Thu",
    },
    {
      data: 0.6,
      day: "Fri",
    },
    {
      data: 0.7,
      day: "Sat",
    },
  ];

  const labels = DetailsChart.map((data) => data?.day);
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: "#01611e",
        data: DetailsChart.map((data) => data?.data),
        barThickness: 23,
        maxBarThickness: 23,
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  return (
    <div style={styles.MainDiv}>
      <Tabs />
      <WaterNetTabs tabsfor={key} />
      <Container fluid>
        <Row>
          <Col xs={12} md={12}>
            <div className="card" style={styles.card1}>
              <img
                src={require("../Assets/drop.png")}
                width="50"
                height="50"
                alt="logo"
              />
              <h3>Paint Shop Bill</h3>
              <img
                src={require("../Assets/bar-chart.png")}
                width="50"
                height="50"
                alt="logo"
              />
            </div>
          </Col>

          {/* <Col xs={8} md={6}>
            <Row>
              <div className="card" style={styles.dropdownCard}>
                <Col xs={1} md={3} style={{ textAlign: "center" }}>
                  Select Year :
                </Col>
                <Col xs={1} md={5}>
                  <Form>
                    <Form.Select
                      value={selectedOption || "default"}
                      onChange={handleSelect}
                    >
                      <option value="default" disabled>
                        Select Year
                      </option>
                      <option value="2023 - 2024">
                        April 2023 - March 2024
                      </option>
                      <option value="2024 - 2025">
                        April 2024 - March 2025
                      </option>
                    </Form.Select>
                  </Form>
                </Col>
                <Col
                  xs={1}
                  md={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: " #fff",
                      borderColor: "#375EAD",
                      color: "#375EAD",
                    }}
                  >
                    Download
                  </Button>{" "}
                </Col>
              </div>
            </Row>
          </Col> */}
          <Col xs={12} md={12}>
            <div className="card" style={styles.card2}>
              {consumptionBillData && (
                <BillingTable data={consumptionBillData} />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="card" style={styles.card3}>
              <BarChart
                data={data}
                titleX={"Consumption (Last 6 Month)"}
                titleY={"Neutrality"}
                width={900}
                height={380}
                color={"#71a6af"}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Bill;
