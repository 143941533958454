const { format, subDays, eachDayOfInterval } = require("date-fns");
const {
  PRESS_SHOP,
  shopWiseMeters,
  M18_METER_NAME,
  M20_METER_NAME,
  M29_METER_NAME,
  M55_METER_NAME,
  M38_METER_NAME,
  M43_METER_NAME,
} = require("./Constants");

const formatLargeRecordsDataTo10Values = (inputArray) => {
  if (inputArray && inputArray.length > 0) {
    //console.log(inputArray);
    const differenceKey = Object.keys(inputArray[0])?.find((key) =>
      key.includes("Difference")
    );
    if (inputArray.length > 11) {
      //console.log("inside greater 11 length ");
      const chunkSize = Math.ceil(inputArray.length / 10);
      const resultArray = [];
      // const differenceKey = Object.keys(inputArray[0]).find((key) =>
      //   key.includes("Difference")
      // );
      resultArray.push(inputArray[0][differenceKey]);
      for (let i = 1; i < inputArray.length; i += chunkSize) {
        const chunk = inputArray.slice(i, i + chunkSize);

        const sum = chunk.reduce((acc, obj) => {
          const numericValue = Number(obj[differenceKey]);
          return isNaN(numericValue) ? acc : acc + numericValue;
        }, 0);

        resultArray.push(sum);
      }
      //console.log(resultArray);
      return resultArray;
    }
    return inputArray.map((item) => item[differenceKey]);
  }
  return [];
};

const formatLargeRecordsLabelsTo10Values = (inputArray) => {
  //  console.log(inputArray);
  if (inputArray && inputArray.length > 11) {
    const chunkSize = Math.ceil(inputArray.length / 10);
    const resultArray = [];
    for (let i = 0; i < inputArray.length; i += chunkSize) {
      resultArray.push(inputArray[i]);
    }
    return resultArray;
  }
  return inputArray;
};

const getCustomLabels = (meterData) => {
  return (
    (meterData &&
      meterData[0]?.D &&
      formatLargeRecordsLabelsTo10Values(
        meterData.map((item, index) => {
          return item?.D ? format(new Date(item?.D), "dd/MM ") : null;
          //return format(dateObj, "dd/MM");
        })
      )) ||
    []
  );
};

const getDefaultVolume = (volumeFromCardData, meterDataArray, attribute) => {
  const defaultVolume =
    volumeFromCardData !== null && volumeFromCardData !== undefined
      ? volumeFromCardData?.toFixed(2)
      : meterDataArray !== undefined && meterDataArray?.length > 0
      ? (
          meterDataArray[meterDataArray.length - 1][attribute] -
          meterDataArray[0][attribute]
        ).toFixed(2)
      : "--";
  return defaultVolume !== "--" ? Math.round(defaultVolume) : defaultVolume;
};

const getCustomVolume = (value) => {
  const customVolume =
    value !== undefined && value !== null
      ? value < 0
        ? "--"
        : value?.toFixed(2)
      : "--";
  return customVolume !== "--" ? Math.round(customVolume) : customVolume;
};

const getCustomVolumeUpdated = (value, inputarray) => {
  const customVolume =
    value !== undefined && value !== null
      ? value < 0
        ? "--"
        : value?.toFixed(2)
      : "--";
  const sum = inputarray?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return customVolume !== "--" ? Math.round(customVolume) : Math.round(sum);
};

const getDefaultLabels = (meterData) => {
  return (
    (meterData &&
      meterData[0]?.T &&
      meterData?.map((item) => {
        return item?.T
          ? format(new Date(`1970-01-01T${item?.T}`), "HH:mm")
          : null;
      })) || ["00:00", "06:00", "13:00", "18:00", "23:00", "00:00"]
  );
};

const getAddition = (value1, value2) => {
  // console.log("value1 , value2", value1, value2);
  const v1 = isNaN(Number(value1)) ? 0 : Number(value1);
  const v2 = isNaN(Number(value2)) ? 0 : Number(value2);
  const total = (v1 + v2).toFixed();
  return total;
};

function getDurationStartAndEnd(days, startDate, endDate) {
  const today = new Date();
  const yesterday = subDays(today, 1);
  if (days && days !== 0) {
    startDate = subDays(yesterday, Number(days));
    endDate = yesterday;
  } else if (startDate && endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  } else {
    startDate = yesterday;
    endDate = today;
  }
  console.log("inside getDuration start and end", startDate, endDate);
  return { startDate, endDate };
}

function getDefaultAxischartLabels() {
  return ["00:00", "00:06", "12:00", "18:00", "23:00", "00:00"];
}

function getDatesArray(dateFilter) {
  // console.log("datefilter inside getDateArray", dateFilter);
  const { startDate, endDate } = getDurationStartAndEnd(
    dateFilter.days,
    dateFilter.startdate,
    dateFilter.enddate
  );
  if (endDate < startDate) {
    return [];
  }
  const datesArray = eachDayOfInterval({ start: startDate, end: endDate });
  const formattedDatesArray = datesArray
    .filter((item) => item > new Date("2024-02-29"))
    ?.map((date) => format(date, "dd/MM"));
  console.log("formattedDatesArray", formattedDatesArray);
  return formattedDatesArray;
}

function generateDataArray(datesArray, resultArray) {
  // console.log("resultarray", resultArray);
  return datesArray.map((date) => {
    const [day, month] = date.split("/");
    const formattedDate = `${month}-${day}`;
    const resultObject = resultArray?.find((obj) =>
      obj.D.endsWith(formattedDate)
    );
    return resultObject ? resultObject : {};
  });
}

function generateMultiAxisDataSet(shopNo, label, volChartData, flag) {
  let dataset = [];
  switch (shopNo) {
    case 1:
      dataset = [
        {
          label: "Press Shop Drinking",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M23)?.map(
                  (item) => item?.M23TVDifference
                )
              : volChartData?.data?.M23?.map((item) => item?.M23TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
        {
          label: "Press Shop Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M61)?.map(
                  (item) => item?.M61TVDifference
                )
              : volChartData?.data?.M61?.map((item) => item?.M61TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
      ];
      break;
    case 2:
      dataset = [
        {
          label: "Weld Shop Drinking Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M24)?.map(
                  (item) => item?.M24TVDifference
                )
              : volChartData?.data?.M24?.map((item) => item?.M24TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: "Weld Shop 'A' Grid Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M62)?.map(
                  (item) => item?.M62TVDifference
                )
              : volChartData?.data?.M62?.map((item) => item?.M62TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
        {
          label: "Weld Shop K Grid Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M17)?.map(
                  (item) => item?.M17TVDifference
                )
              : volChartData?.data?.M17?.map((item) => item?.M17TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
      ];
      break;
    case 3:
      dataset = [
        {
          label: "Paint Shop Drinking Water Line",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M21)?.map(
                  (item) => item?.M21TVDifference
                )
              : volChartData?.data?.M21?.map((item) => item?.M21TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: "Paint Shop Canteen-01",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M18)?.map(
                  (item) => item?.M18TVDifference
                )
              : volChartData?.data?.M18?.map((item) => item?.M18TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y",
        },
        {
          label: "Paint Shop Canteen-02",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M20)?.map(
                  (item) => item?.M20TVDifference
                )
              : volChartData?.data?.M20?.map((item) => item?.M20TV),
          borderColor: "#00ff40",
          backgroundColor: "#00ff40",
          yAxisID: "y",
        },
        {
          label: "Paint Shop 'O' Grid Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M15)?.map(
                  (item) => item?.M15TVDifference
                )
              : volChartData?.data?.M15?.map((item) => item?.M15TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y1",
        },
        {
          label: "Paint Shop L Grid Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M58)?.map(
                  (item) => item?.M58TVDifference
                )
              : volChartData?.data?.M58?.map((item) => item?.M58TV),
          borderColor: "#ff00ff",
          backgroundColor: "#ff00ff",
          yAxisID: "y1",
        },
      ];
      break;
    case 4:
      dataset = [
        {
          label: "TCF 2 Drinking Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M32)?.map(
                  (item) => item?.M32TVDifference
                )
              : volChartData?.data?.M32?.map((item) => item?.M32TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: "TCF 1 Drinking Water line",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M39)?.map(
                  (item) => item?.M39TVDifference
                )
              : volChartData?.data?.M39?.map((item) => item?.M39TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y",
        },
        {
          label: "TCF 1A Process Water Line",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M34)?.map(
                  (item) => item?.M34TVDifference
                )
              : volChartData?.data?.M34?.map((item) => item?.M34TV),
          borderColor: "#00ff40",
          backgroundColor: "#00ff40",
          yAxisID: "y1",
        },
        {
          label: "TCF 1B Process Water Line",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M35)?.map(
                  (item) => item?.M35TVDifference
                )
              : volChartData?.data?.M35?.map((item) => item?.M35TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y1",
        },
        {
          label: "TCF 1C Process Water Line",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M36)?.map(
                  (item) => item?.M36TVDifference
                )
              : volChartData?.data?.M36?.map((item) => item?.M36TV),
          borderColor: "#ff00ff",
          backgroundColor: "#ff00ff",
          yAxisID: "y1",
        },
      ];
      break;
    case 5:
      dataset = [
        {
          label: "TCF 2 Drinking Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M32)?.map(
                  (item) => item?.M32TVDifference
                )
              : volChartData?.data?.M32?.map((item) => item?.M32TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
        {
          label: "TCF-2 Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M33)?.map(
                  (item) => item?.M33TVDifference
                )
              : volChartData?.data?.M33?.map((item) => item?.M33TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
      ];
      break;
    case 6:
      dataset = [
        {
          label: "Drinking Water Supply To JLR",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M1)?.map(
                  (item) => item?.M1TVDifference
                )
              : volChartData?.data?.M1?.map((item) => item?.M1TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
      ];
      break;
    case 7:
      dataset = [
        {
          label: "Engine Shop Drinking Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M27)?.map(
                  (item) => item?.M27TVDifference
                )
              : volChartData?.data?.M27?.map((item) => item?.M27TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: "Omega PDI Drinking",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M42)?.map(
                  (item) => item?.M42TVDifference
                )
              : volChartData?.data?.M42?.map((item) => item?.M42TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y",
        },
        {
          label: "Scanning Post Drinking Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M63)?.map(
                  (item) => item?.M63TVDifference
                )
              : volChartData?.data?.M63?.map((item) => item?.M63TV),
          borderColor: "#00ff40",
          backgroundColor: "#00ff40",
          yAxisID: "y",
        },
        {
          label: "Scrap Yard Drinking",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M49)?.map(
                  (item) => item?.M49TVDifference
                )
              : volChartData?.data?.M49?.map((item) => item?.M49TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
        {
          label: "Engine Shop Process Water",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M40)?.map(
                  (item) => item?.M40TVDifference
                )
              : volChartData?.data?.M40?.map((item) => item?.M40TV),
          borderColor: "#ff00ff",
          backgroundColor: "#ff00ff",
          yAxisID: "y1",
        },
        {
          label: "Solar System 01 Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M31)?.map(
                  (item) => item?.M31TVDifference
                )
              : volChartData?.data?.M31?.map((item) => item?.M31TV),
          borderColor: "#FF8000",
          backgroundColor: "#FF8000",
          yAxisID: "y1",
        },
        {
          label: "Solar System 02 Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M45)?.map(
                  (item) => item?.M45TVDifference
                )
              : volChartData?.data?.M45?.map((item) => item?.M45TV),
          borderColor: "#660066",
          backgroundColor: "#660066",
          yAxisID: "y1",
        },
      ];
      break;
    case 8:
      dataset = [
        {
          label: "Drinking Water Supply To J Block",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M6)?.map(
                  (item) => item?.M6TVDifference
                )
              : volChartData?.data?.M6?.map((item) => item?.M6TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
      ];
      break;
    case 12:
      dataset = [
        {
          label: "Compressor House Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M41)?.map(
                  (item) => item?.M41TVDifference
                )
              : volChartData?.data?.M41?.map((item) => item?.M41TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y",
        },
        {
          label: "Compressor Room Process",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M59)?.map(
                  (item) => item?.M59TVDifference
                )
              : volChartData?.data?.M59?.map((item) => item?.M59TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
      ];
      break;
    case 13:
      dataset = [
        {
          label: "ETP | RO Reject",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M7)?.map(
                  (item) => item?.M7TVDifference
                )
              : volChartData?.data?.M7?.map((item) => item?.M7TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: "ETP | ACF 2 Out",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M8)?.map(
                  (item) => item?.M8TVDifference
                )
              : volChartData?.data?.M8?.map((item) => item?.M8TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
        {
          label: "ETP | JLR Garden",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M9)?.map(
                  (item) => item?.M9TVDifference
                )
              : volChartData?.data?.M9?.map((item) => item?.M9TV),
          borderColor: "#00ff40",
          backgroundColor: "#00ff40",
          yAxisID: "y",
        },
        {
          label: "ETP | RO Transfer",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M10)?.map(
                  (item) => item?.M10TVDifference
                )
              : volChartData?.data?.M10?.map((item) => item?.M10TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y1",
        },
        {
          label: "ETP INLET",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M11)?.map(
                  (item) => item?.M11TVDifference
                )
              : volChartData?.data?.M11?.map((item) => item?.M11TV),
          borderColor: "#ff00ff",
          backgroundColor: "#ff00ff",
          yAxisID: "y",
        },
        {
          label: "ETP + STP INLET",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M12)?.map(
                  (item) => item?.M12TVDifference
                )
              : volChartData?.data?.M12?.map((item) => item?.M12TV),
          borderColor: "#FF8000",
          backgroundColor: "#FF8000",
          yAxisID: "y1",
        },
        {
          label: "STP INLET",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M13)?.map(
                  (item) => item?.M13TVDifference
                )
              : volChartData?.data?.M13?.map((item) => item?.M13TV),
          borderColor: "#660066",
          backgroundColor: "#660066",
          yAxisID: "y",
        },
        {
          label: "STP OUTLET",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M14)?.map(
                  (item) => item?.M14TVDifference
                )
              : volChartData?.data?.M14?.map((item) => item?.M14TV),
          borderColor: "#f7db68",
          backgroundColor: "#f7db68",
          yAxisID: "y1",
        },
        {
          label: "Fire Hydrant PH-06",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M56)?.map(
                  (item) => item?.M56TVDifference
                )
              : volChartData?.data?.M56?.map((item) => item?.M56TV),
          borderColor: "#d142f5",
          backgroundColor: "#d142f5",
          yAxisID: "y",
        },
        {
          label: "RO Water To PH-06 Hydrant",
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M57)?.map(
                  (item) => item?.M57TVDifference
                )
              : volChartData?.data?.M57?.map((item) => item?.M57TV),
          borderColor: "#b7e68c",
          backgroundColor: "#b7e68c",
          yAxisID: "y1",
        },
      ];
      break;
    case 14:
      dataset = [
        {
          label: M18_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M18)?.map(
                  (item) => item?.M18TVDifference
                )
              : volChartData?.data?.M18?.map((item) => item?.M18TV),
          borderColor: "#ab1005",
          backgroundColor: "#ab1005",
          yAxisID: "y",
        },
        {
          label: M20_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M20)?.map(
                  (item) => item?.M20TVDifference
                )
              : volChartData?.data?.M20?.map((item) => item?.M20TV),
          borderColor: "#3d872e",
          backgroundColor: "#3d872e",
          yAxisID: "y1",
        },
        {
          label: M29_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M29)?.map(
                  (item) => item?.M29TVDifference
                )
              : volChartData?.data?.M29?.map((item) => item?.M29TV),
          borderColor: "#fc2403",
          backgroundColor: "#fc2403",
          yAxisID: "y",
        },
        {
          label: M55_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M55)?.map(
                  (item) => item?.M55TVDifference
                )
              : volChartData?.data?.M55?.map((item) => item?.M55TV),
          borderColor: "#d4d41c",
          backgroundColor: "#d4d41c",
          yAxisID: "y1",
        },
        {
          label: M38_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M38)?.map(
                  (item) => item?.M38TVDifference
                )
              : volChartData?.data?.M38?.map((item) => item?.M38TV),
          borderColor: "#240a94",
          backgroundColor: "#240a94",
          yAxisID: "y1",
        },
        {
          label: M43_METER_NAME,
          data:
            flag === "custom"
              ? generateDataArray(label, volChartData?.data?.M43)?.map(
                  (item) => item?.M43TVDifference
                )
              : volChartData?.data?.M43?.map((item) => item?.M43TV),
          borderColor: "#200729",
          backgroundColor: "#200729",
          yAxisID: "y1",
        },
      ];
      break;
    default:
      break;
  }
  return dataset;
}

function getShopWisePdfTableHeaders(shopNo) {
  let headers = [];
  switch (shopNo) {
    case PRESS_SHOP:
      headers = shopWiseMeters[PRESS_SHOP]["ReportHeaders"];
      break;

    default:
      break;
  }
  return headers;
}

const TIME_ARR = ["00:00", "06:00", "12:00", "18:00", "23:00"];

function getShopWisePdfTableBody(shopNo, data, flag) {
  console.log("data", data);
  let body = [];
  switch (shopNo) {
    case PRESS_SHOP:
      const rows = [];
      const maxLength = Math.max(data.M23.length, data.M61.length);

      while (data.M23?.length < maxLength) {
        data.M23.push({ M23TV: null, M23TVDifference: null });
      }

      while (data.M61?.length < maxLength) {
        data.M61.push({ M61TV: null, M61TVDifference: null });
      }
      if (flag === 0) {
        for (let i = 0; i < maxLength; i++) {
          const row = [
            `${data.M23[i]?.D} ${TIME_ARR[i]}`,
            data.M23[i]?.M23TV?.toFixed() || "--",
            data.M61[i]?.M61TV?.toFixed() || "--",
          ];
          rows.push(row);
        }
      } else {
        for (let i = 0; i < maxLength; i++) {
          const row = [
            data.M23[i]?.D,
            data.M23[i]?.M23TVDifference?.toFixed(),
            data.M61[i]?.M61TVDifference?.toFixed(),
          ];
          rows.push(row);
        }
      }

      body = rows;
      break;

    default:
      break;
  }
  return body;
}

const getFormattedNumber = (inputNumber) => {
  return isNaN(Number(inputNumber)) || Number(inputNumber) < 0
    ? "--"
    : Math.round(inputNumber);
};

module.exports = {
  getFormattedNumber,
  getCustomVolumeUpdated,
  formatLargeRecordsDataTo10Values,
  formatLargeRecordsLabelsTo10Values,
  getCustomLabels,
  getDefaultVolume,
  getCustomVolume,
  getDefaultLabels,
  getAddition,
  getDurationStartAndEnd,
  getDatesArray,
  generateDataArray,
  generateMultiAxisDataSet,
  getShopWisePdfTableHeaders,
  getShopWisePdfTableBody,
  getDefaultAxischartLabels,
};
