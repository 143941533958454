import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Container,
  Row,
  Col,
  Button,
  Form as BootstrapForm,
  Alert,
} from "react-bootstrap";
import LoginLogo from "../Assets/LoginLogo.png";
import axios from "axios";
import "./styles/styles.css";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const styles = {
  mainImage: {
    height: "100vh",
    width: "100%",
    objectFit: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${LoginLogo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingLeft: "133px",
    color: "white",
  },
  Feedback: {
    fontSize: "12px",
    color: "#dc3545",
    marginTop: "5px",
    textAlign: "left",
  },
};

const UserLogin = () => {
  const URL = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const secretKey = 'qwertyuiop';
  const encryptedToken = (data) => {
    if (!data || !secretKey) {
      console.error('Data or Secret Key is missing');
      return null;
  }
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  const handleSuccessfulLogin = (data, setStatus, setSubmitting) => {

    if (!data?.status) {
      setStatus({ error: "Invalid username or password" });
    } else {
      sessionStorage.setItem("token", encryptedToken(data?.data));
      setStatus(null);
      navigate("/homepage");
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, setStatus, resetForm }
  ) => {
    let response; 
    try {
       response = await axios.post(`${URL}login-with-password`, values); // Adjust the API endpoint accordingly
      handleSuccessfulLogin(response.data, setStatus);
    } catch (error) {
      // Handle login error
  
      setStatus({ error: error?.message });
      if(error?.code === 'ERR_BAD_REQUEST'){
        setStatus({ error: 'Too many attempt. Please try again after 15 minutes' });
      }
    
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col
          xs={12}
          md={12}
          style={{ margin: "auto", height: "100%", width: "500px" }}
        >
          <div
            className="login-form-container"
            style={{ padding: "10px", textAlign: "center", height: "60%" }}
          >
            <img
              src={require("../Assets/Netp_Logo_2.10.png")}
              width="225"
              height="75"
              alt="logo"
              style={{ cursor: "pointer", marginTop: 20, marginBottom: 50 }}
            />
            <h1
              style={{
                color: "var(--black, #0F0F0F)",
                fontSize: "18.667px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "0.833px",
                textAlign: "center",
              }}
            >
              LOGIN
            </h1>
            <div
              style={{
                borderTop: "2px solid #375EAD",
                background: "#FFF",
                boxShadow:
                  "0px 3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13)",
                padding: "40px",
                margin: "30px",
              }}
            >
              <Formik
                initialValues={{ mobileno: "", password: "" }}
                onSubmit={handleSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.mobileno) {
                    errors.mobileno = "Please enter registered mobile no.";
                  } else if (!/^\d{10}$/i.test(values.mobileno)) {
                    errors.mobileno = "Invalid mobile number";
                  }
                  if (!values.password) {
                    errors.password = "Please enter password";
                  }
                  return errors || null;
                }}
              >
                {({ isSubmitting, status }) => (
                  <Form autoComplete="off">
                    {status && status.error && (
                      <Alert variant="danger" style={{ marginBottom: 25 }}>
                        {status.error}
                      </Alert>
                    )}

                    <BootstrapForm.Group
                      controlId="username"
                      style={{ height: "100px" }}
                    >
                      <BootstrapForm.Label
                        style={{
                          float: "left",
                          fontWeight: "bold",
                          fontSize: "13.333px",
                          color: "#375EAD",
                        }}
                      >
                        Mobile No
                      </BootstrapForm.Label>
                      <Field
                        type="text"
                        name="mobileno"
                        as={BootstrapForm.Control}
                        className="form-control"
                        style={{
                          height: "34.666px",
                          borderRadius: "2.667px",
                          border: "0.667px solid var(--primary-blue, #375EAD)",
                          background: "#FFF",
                        }}
                      />
                      <ErrorMessage
                        name="mobileno"
                        component="div"
                        style={styles.Feedback}
                      />
                    </BootstrapForm.Group>
                    <BootstrapForm.Group
                      controlId="password"
                      style={{ height: "100px" }}
                    >
                      <BootstrapForm.Label
                        style={{
                          float: "left",
                          fontWeight: "bold",
                          fontSize: "13.333px",
                          color: "#375EAD",
                        }}
                      >
                        Password
                      </BootstrapForm.Label>
                      <Field
                        type="password"
                        name="password"
                        as={BootstrapForm.Control}
                        autoComplete="off" 
                        className="form-control"
                        style={{
                          height: "34.666px",
                          borderRadius: "2.667px",
                          border: "0.667px solid var(--primary-blue, #375EAD)",
                          background: "#FFF",
                        }}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        style={styles.Feedback}
                      />
                    </BootstrapForm.Group>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button type="submit" class="button button1">
                        LOGIN
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "20%",
              justifyContent: "center",
            }}
          >
            <div>Developed by </div>
            <img
              src={require("../Assets/DhruvaLogo.png")}
              width="116"
              height="45"
              alt="logo"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserLogin;
